import ContentHero from "@components/ContentHero"
import { LastUpdate } from "@components/LastUpdate"
import Seo from "@components/Seo"
import { useMarkdownPage } from "@hooks/use-markdown-page"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import { PageProps } from "gatsby"
import React, { FC } from "react"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    paddingTop: 32,
    paddingBottom: 32,
  },
}))

const Page: FC<PageProps<any>> = ({ location, pageContext }) => {
  const res = useMarkdownPage(location.pathname, (pageContext as any).language)
  const classes = useCss()

  return (
    <>
      <Seo title={res.title} />
      <ContentHero
        description={<LastUpdate date={res.lastupdate} />}
        title={res.title}
      />
      <Container maxWidth="md" className={classes.container}>
        <div dangerouslySetInnerHTML={{ __html: res.html }} />
      </Container>
    </>
  )
}

export default Page
